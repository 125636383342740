const cgv = () => {

 

    let chkBox = document.getElementById("termsChkbx");
    let btn = document.getElementById("someBtn");
    let btn2 = document.getElementById("someBtn2");
    let btn3 = document.getElementById("someBtn3");
    
    chkBox.addEventListener("click", function() {
      if(this.checked == true) {
        btn.classList.remove("disabled");
      } else {
        btn.classList.add("disabled");
      }
    });

    chkBox.addEventListener("click", function() {
        if(this.checked == true) {
          btn2.classList.remove("disabled");
        } else {
          btn2.classList.add("disabled");
        }
      });

      chkBox.addEventListener("click", function() {
        if(this.checked == true) {
          btn3.classList.remove("disabled");
        } else {
          btn3.classList.add("disabled");
        }
      });




      let chkBox2 = document.getElementById("termsChkbx2");
      let btn4 = document.getElementById("someBtn4");
      let btn5 = document.getElementById("someBtn5");
      let btn6 = document.getElementById("someBtn6");
      
      chkBox2.addEventListener("click", function() {
        if(this.checked == true) {
          btn4.classList.remove("disabled");
        } else {
          btn4.classList.add("disabled");
        }
      });
  
      chkBox2.addEventListener("click", function() {
          if(this.checked == true) {
            btn5.classList.remove("disabled");
          } else {
            btn5.classList.add("disabled");
          }
        });
  
        chkBox2.addEventListener("click", function() {
          if(this.checked == true) {
            btn6.classList.remove("disabled");
          } else {
            btn6.classList.add("disabled");
          }
        });

       
        let chkBox3 = document.getElementById("termsChkbx3");
        let btn7 = document.getElementById("someBtn70");
        let btn8 = document.getElementById("someBtn8");
        let btn9 = document.getElementById("someBtn9");
        
        chkBox3.addEventListener("click", function() {
          if(this.checked == true) {
            btn7.classList.remove("disabled");
          } else {
            btn7.classList.add("disabled");
          }
        });
    
        chkBox3.addEventListener("click", function() {
            if(this.checked == true) {
              btn8.classList.remove("disabled");
            } else {
              btn8.classList.add("disabled");
            }
          });
    
          chkBox3.addEventListener("click", function() {
            if(this.checked == true) {
              btn9.classList.remove("disabled");
            } else {
              btn9.classList.add("disabled");
            }
          });


               
          let chkBox4 = document.getElementById("termsChkbx4");
          let btn10 = document.getElementById("someBtn10");
          let btn11 = document.getElementById("someBtn11");
          let btn12 = document.getElementById("someBtn12");
          
          chkBox4.addEventListener("click", function() {
            if(this.checked == true) {
              btn10.classList.remove("disabled");
            } else {
              btn10.classList.add("disabled");
            }
          });
      
          chkBox4.addEventListener("click", function() {
              if(this.checked == true) {
                btn11.classList.remove("disabled");
              } else {
                btn11.classList.add("disabled");
              }
            });
      
            chkBox4.addEventListener("click", function() {
              if(this.checked == true) {
                btn12.classList.remove("disabled");
              } else {
                btn12.classList.add("disabled");
              }
            });  

 
 }
 
  
 
  
 
 export { cgv };
 
  